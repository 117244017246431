import React, { FunctionComponent } from 'react'

import buildEmailLink, { contactEmail } from '../utils/emailLink'

const contactPhone = '07753 354387'
const emailLink = buildEmailLink()

const Contact: FunctionComponent = () => (
  <div className="soft push-bottom">
    <p>
      The easiest way to contact AM Wellbeing for inquiries and bookings is to email{' '}
      <span className="em">
        <a href={emailLink}>{contactEmail}</a>
      </span>
    </p>
    <br />
    <p>
      Alternatively, call Anne in office hours on{' '}
      <span className="em">
        <a href={`tel:${contactPhone}`}>{contactPhone}</a>
      </span>
    </p>
  </div>
)

export default Contact
