import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import Hero from '../components/Hero'
import Layout, { FeaturedImageData } from '../components/Layout'
import Content from '../components/Content'
import Credits from '../components/Credits'
import Upcoming from '../components/Upcoming'

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        introduction
        highlightColour
        showUpcoming
        credits {
          name
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 160)
            }
          }
        }
        featuredImage {
          desktop: childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          mobile: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 512)
          }
        }
      }
      html
    }
  }
`

interface Props {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string
        introduction: null | string
        highlightColour: string
        featuredImage: FeaturedImageData
        credits: null | Array<{
          name: string
          image: any
        }>
        showUpcoming?: boolean
      }
      html: string
    }
  }
}

const PageTemplate: FunctionComponent<Props> = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { title, introduction, highlightColour, featuredImage, credits, showUpcoming } = frontmatter

  return (
    <Layout highlightColour={highlightColour} featuredImageData={featuredImage}>
      <Hero title={title} introduction={introduction} />
      <Content content={html} />
      <Credits credits={credits} />
      {showUpcoming && <Upcoming />}
    </Layout>
  )
}

export default PageTemplate
