import React, { FunctionComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const PageHelmet: FunctionComponent = () => (
  <StaticQuery
    query={query}
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    render={data => (
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: data.site.siteMetadata.description },
          { name: 'keywords', content: 'dru yoga, mindfulness, wellbeing, am wellbeing' },
        ]}
      />
    )}
  />
)

export default PageHelmet
