import React, { FunctionComponent } from 'react'

import scottishFlag from '../assets/scottish-flag.svg'

const YEAR = new Date().getFullYear()

const PageFooter: FunctionComponent = () => (
  <footer className="soft flex flex--row flex--justify-space-between">
    <div>© {YEAR} AM Wellbeing</div>
    <div>
      <img className="scottish-flag" src={scottishFlag} alt="Scottish Flag" />
    </div>
  </footer>
)

export default PageFooter
