import React, { FunctionComponent } from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

interface Props {
  credits?: Array<{
    name: string
    image: any
  }>
}

const Credits: FunctionComponent<Props> = ({ credits }) => {
  if (!(credits && credits.length > 0)) return null

  return (
    <div className="credits">
      {credits.map(credit => (
        <Img
          key={credit.name}
          className="credits-logo"
          alt={credit.name}
          image={credit.image.childImageSharp.gatsbyImageData}
        />
      ))}
    </div>
  )
}

export default Credits
