/* eslint-disable react/no-danger */
import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

interface Props {
  title: string
  introduction?: string
}

const Hero: FunctionComponent<Props> = ({ title, introduction }) => {
  const titleHtml = title.replace(/_(.+)_/, (_, text) => `<br /><em>${text}</em>`)
  const introductionHtml =
    introduction &&
    introduction
      .replace(/_(.+)_/g, (_, text) => `<em>${text}</em>`)
      .replace(/\*\*(.[^*]+)\*\*/g, (_, text) => `<strong>${text}</strong>`)

  return (
    <div className={classnames('hero', !introduction && 'hero--no-intro')}>
      <h1 className="hero-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />
      {introduction && <p className="hero-text" dangerouslySetInnerHTML={{ __html: introductionHtml }} />}
    </div>
  )
}

export default Hero
