/* eslint-disable react/no-danger */
import React, { FunctionComponent } from 'react'

interface Props {
  content: string
}

const HTMLContent: FunctionComponent<Props> = ({ content }) => (
  <section className="content-body" dangerouslySetInnerHTML={{ __html: content }} />
)

export default HTMLContent
