import React, { FunctionComponent } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { compareAsc, format, isFuture, parseISO } from 'date-fns'

const query = graphql`
  query {
    results: allFile(filter: { sourceInstanceName: { eq: "classes" }, extension: { eq: "md" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              description
              startDateTime(formatString: "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
            }
          }
        }
      }
    }
  }
`

interface QueryResults {
  results: {
    edges: Array<{
      node: {
        childMarkdownRemark: {
          frontmatter: {
            title: string
            description: string
            startDateTime: string
          }
        }
      }
    }>
  }
}

const Upcoming: FunctionComponent = () => {
  const data = useStaticQuery<QueryResults>(query)
  const upcoming = data.results.edges
    .map(({ node }) => ({ ...node.childMarkdownRemark.frontmatter }))
    .map(event => ({ ...event, startDateTime: parseISO(event.startDateTime) }))
    .filter(event => isFuture(event.startDateTime))
    .sort((a, b) => compareAsc(a.startDateTime, b.startDateTime))
    .slice(0, 5)

  return (
    <section>
      <h2>Upcoming classes</h2>
      {upcoming.length === 0 && <p>There are no upcoming classes.</p>}
      {upcoming.map(event => (
        <div key={event.startDateTime.toISOString()} className="event">
          <h3 className="event-title">
            <em>{event.title}</em>
          </h3>
          <p className="event-time">{format(event.startDateTime, 'EEEE, do MMMM yyyy - h:mmaaa')}</p>
          <p className="event-description">{event.description}</p>
        </div>
      ))}
    </section>
  )
}

export default Upcoming
