import React, { FunctionComponent, useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

const menuLinks = [
  { title: 'Home', to: '' },
  { title: 'About', to: 'about' },
  { title: 'Dru Yoga', to: 'dru-yoga' },
  { title: 'Mindfulness', to: 'mindfulness' },
]

const useOutsideAlerter = (ref, callback): void => {
  const handleClickOutside = (event): void => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
}

const NavBar: FunctionComponent = () => {
  const [isOpen, setOpen] = useState(false)
  const wrapperRef = useRef(null)

  const toggleOpen = (): void => setOpen(!isOpen)
  const close = (): void => setOpen(false)

  useOutsideAlerter(wrapperRef, close)

  return (
    <div className="navbar" ref={wrapperRef}>
      <div
        className={classNames('navbar-menu', isOpen && 'is-open')}
        style={{ height: isOpen ? `${menuLinks.length * 3}rem` : 0 }}
      >
        {menuLinks.map(({ to, title }) => (
          <Link key={to} className="navbar-menu__item" to={`/${to}`}>
            <span className="em-on-hover">{title}</span>
          </Link>
        ))}
      </div>
      <div className="navbar-body">
        <div className="navbar-button" onClick={toggleOpen} role="button" onKeyDown={toggleOpen} tabIndex={0}>
          {isOpen ? 'Close' : 'Menu'}
        </div>
        <div className="navbar-brand em-on-hover">
          <Link to="/">AM Wellbeing.</Link>
        </div>
      </div>
    </div>
  )
}

export default NavBar
