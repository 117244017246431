import React, { FunctionComponent } from 'react'
import { GatsbyImage as Img, getImage, ImageDataLike } from 'gatsby-plugin-image'

import PageHelmet from './PageHelmet'
import NavBar from './NavBar'
import Footer from './Footer'
import Contact from './Contact'

import '../styles/main.scss'

interface FeaturedImageData {
  mobile: ImageDataLike
  desktop: ImageDataLike
}

interface Props {
  highlightColour: string
  featuredImageData: FeaturedImageData
}

const Layout: FunctionComponent<Props> = ({ children, highlightColour, featuredImageData }) => (
  <>
    <style>
      {`
        em::before,
        .em::before,
        .em-on-hover::before {
          background-color: ${highlightColour};
        }
      `}
    </style>
    <PageHelmet />
    <NavBar />
    <div className="site-container">
      <div className="content-container">
        <div className="hidden-on-desktop">
          <Img alt="Hero image" image={getImage(featuredImageData.mobile)} style={{ height: '50vh' }} />
        </div>
        <div className="content soft">{children}</div>
        <Contact />
        <Footer />
      </div>
      <Img className="hidden-on-mobile" alt="Hero image" image={getImage(featuredImageData.desktop)} />
    </div>
  </>
)

export default Layout
export { FeaturedImageData }
