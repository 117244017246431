import qs from 'qs'

const contactEmail = 'hello@amwellbeing.co.uk'

const buildEmailLink = ({ subject, body }: { subject?: string; body?: string } = {}): string => {
  const emailLink = `mailto:${contactEmail}`
  const emailQuery = qs.stringify({
    subject,
    body,
  })

  if (emailQuery) {
    return `${emailLink}?${emailQuery}`
  }

  return emailLink
}

export default buildEmailLink
export { contactEmail }
